export const options = {
  'Lifestyle': [
    'Restaurant','Bar','Hitch','Family','Dating'
  ],
  'Hobbies': [
    'Nature','Animals','Movies', 'Gaming', 'Music'
  ],
  'Social': [
    'Events', 'Party','Cycling', 'Others'
  ]
};
